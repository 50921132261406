import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const containerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
  }
  return (
    <Layout>
      <SEO title="404 - Page Not Found" />
      <div style={containerStyle}>
        <h1>Oops!</h1>
        <p>We can't seem to find the page you're looking for.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
